import { FieldInputElement } from "./Element/FieldInputElement";
import { DOMTemplate } from "../denki";
import { FormElement } from "./Element/FormElement";
import { FieldCompositeElement } from "./FieldCompositeElement";

export class FieldBox extends FormElement {
  public onToggleOpen: (arg0: FieldBox) => void;
  private toggleButton: HTMLButtonElement;
  private fieldInputs: FieldInputElement[] = [];
  public id: string;
  private readonly fieldsElement: HTMLElement;

  constructor(id: string, template: DOMTemplate) {
    super(template.get("field-box"));
    const element = this.element;
    this.id = id;
    this.fieldsElement = <HTMLElement>element.querySelector("[data-role=fields]");

    this.toggleButton = element.querySelector(".toggle");
    element.querySelector(".row").addEventListener('click', (e) => {
      this.toggleClose();
    });
  }

  public indexOfFieldInput(id: string): number {
    for (let i = 0, l = this.fieldInputs.length; i < l; i++) {
      const fieldInput = this.fieldInputs[i];
      if (fieldInput.id === id) {
        return i;
      }
    }
    throw new Error(`field input was not found: ${id}`);
  }

  get numberOfFieldInputs(): number {
    return this.fieldInputs.length;
  }

  public fieldInput(id: string): FieldInputElement {
    const index = this.indexOfFieldInput(id);
    return this.fieldInputs[index];
  }

  public fieldInputAtIndex(index: number): FieldInputElement {
    return this.fieldInputs[index];
  }

  public appendFieldInput(input: FieldInputElement, decorator: HTMLElement = null) {
    this.fieldInputs.push(input);
    if (decorator) {
      decorator.appendChild(input.element);
      this.fieldsElement.appendChild(decorator);
    } else {
      this.fieldsElement.appendChild(input.element);
    }
  }

  public removeFieldInput(input: FieldInputElement) {
    const index = this.indexOfFieldInput(input.id);
    if (index === -1) throw new Error(`field input ${input.id} not found`);
    this.fieldInputs.splice(index, 1);
    this.fieldsElement.removeChild(this.fieldsElement.children.item(index));
  }

  // TODO: FieldCompositeElementに限らない
  public updateFieldInput(newInput: FieldCompositeElement, id: string) {
    const fieldInput = <FieldCompositeElement>this.fieldInput(id);
    const oldNum = fieldInput.elements.length;
    const newNum = newInput.elements.length;
    if (oldNum > newNum) {
      while (fieldInput.elements.length > newNum) {
        fieldInput.removeElement(fieldInput.elements[fieldInput.elements.length - 1]);
      }
    } else if (oldNum < newNum) {
      for (let i = oldNum; i < newNum; i++) {
        const element = newInput.elements[i];
        newInput.removeElement(element);
        fieldInput.addElement(element);
      }
    }
    fieldInput.update();
  }

  public toggleClose() {
    this.toggleButton.classList.toggle("opened");
    this.element.classList.toggle("closed");
    if (this.onToggleOpen) {
      this.onToggleOpen(this);
    }
  }

  public open() {
    if (this.element.classList.contains("closed")) {
      this.toggleClose();
    }
  }
}