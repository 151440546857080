import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { OptionType } from "../../digitalForm";

export class FormSelectElement extends FormElement implements FieldInputElement {
  public id: string;
  disabled: boolean;
  validationErrors: string[];
  public readonly fieldsElement: HTMLElement;
  public readonly selectElement: HTMLSelectElement;

  constructor(namespace: string, name: string, options: OptionType[], template: DOMTemplate) {
    super(template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");

    const id = `${namespace}-${name}`;
    this.id = id;
    const select = node('select', { id });
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i];
      const value = option.value;
      const label = option.label;
      const optionName = `${name}-${value}`;
      const elementId = `${namespace}-${optionName}`;
      select.appendChild(node('option', {
        'data-id': elementId,
        'data-name': optionName,
        value
      }, label));
    }
    select.addEventListener("change", e => {
      this.value = select.value;
    });
    this.virtualInput.setAttribute("data-id", id);
    this.selectElement = select;
    this.fieldsElement.appendChild(select);
  }

  public focus() {
    this.selectElement.focus();
  }
}