import { FieldBox } from "./FieldBox";
import { DOMTemplate } from "../denki";
import { FieldInputElement } from "./Element/FieldInputElement";

export class DigitalForm {
  public readonly element;
  private _bar: HTMLElement;
  private _boxes: FieldBox[] = [];
  private _fields: HTMLElement;
  public readonly titleElement?: HTMLElement;

  constructor(template: DOMTemplate) {
    let wrapper = template.get("digital-form");
    this.titleElement = wrapper.querySelector("[data-role=title]");
    this._fields = <HTMLElement>wrapper.querySelector("[data-role=fields]");
    this.element = wrapper;
  }

  get title() {
    if (!this.titleElement) return "";
    return this.titleElement.textContent;
  }

  set title(title: string) {
    if (!this.titleElement) return;
    this.titleElement.textContent = title;
  }

  get numberOfBoxes(): number {
    return this._boxes.length;
  }

  indexOfBox(id: string): number {
    for (let i = 0, l = this._boxes.length; i < l; i++) {
      const box = this._boxes[i];
      if (box.id === id) {
        return i;
      }
    }
    throw new Error(`field input was not found: ${id}`);
  }

  box(id: string): FieldBox {
    const index = this.indexOfBox(id);
    return this._boxes[index];
  }

  boxAtIndex(index: number): FieldBox {
    return this._boxes[index];
  }

  appendBox(box: FieldBox, decorator: HTMLElement = null) {
    this._boxes.push(box);
    if (decorator) {
      decorator.appendChild(box.element);
      this._fields.appendChild(decorator);
    } else {
      this._fields.appendChild(box.element);
    }
    box.element.querySelector(".num").textContent = String(this._boxes.length);
  }

  get data() {
    const dataFields = <[HTMLElement]>this.element.querySelectorAll("[data-name]");
    const result = {};
    for (let i = 0, l = dataFields.length; i < l; i++) {
      const field = dataFields[i];
      result[field.getAttribute('data-id')] = field.value;
    }
    return result;
  }

  set data(data) {
    const dataFields = <[HTMLElement]>this.element.querySelectorAll("[data-id]");
    for (let i = 0, l = dataFields.length; i < l; i++) {
      const field = dataFields[i];
      const key = field.getAttribute('data-id');
      if (data) {
        const value = data[key];
        if (value) {
          field.value = value;
        }
      }
    }
  }
}
