import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { OptionType } from "../../digitalForm";

export class FormRadioElement extends FormElement implements FieldInputElement {
  public id: string;
  disabled: boolean;
  validationErrors: string[];
  public readonly fieldsElement: HTMLElement;
  private readonly map: { [key: string]: HTMLInputElement } = {};

  constructor(namespace: string, name: string, options: OptionType[], template: DOMTemplate) {
    super(template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");

    const fieldset = node('div', { "class": "radio_wrapper" });
    const id = `${namespace}-${name}`;
    this.virtualInput.setAttribute("data-id", id);
    this.id = id;
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i];
      const key = option.value;
      const label = option.label;
      const optionName = `${name}-${key}`;
      const dataId = `${namespace}-${optionName}`;
      const elementId = `form_element-${dataId}`;
      const input = node('input', {
        type: 'radio',
        name: id,
        'data-name': optionName,
        'data-id': dataId,
        id: elementId,
        value: key
      });
      input.addEventListener("change", () => {
        this.value = key;
      });
      this.map[key] = input;
      // if (data.default && data.default === key) {
      //   input.checked = true;
      // }
      const wrap = node('div', { "class": "input_wrapper" });
      wrap.appendChild(input);
      wrap.appendChild(node('label', { for: elementId }, label));
      fieldset.appendChild(wrap);

      this.fieldsElement.appendChild(fieldset);
    }
  }

  public get mapKeys(): string[] {
    return Object.keys(this.map);
  }

  public subInput(key: string) {
    return this.map[key];
  }

  public focus() {
  }
}