import {
    DigitalApplicationDefinition
} from '../../../src/digitalForm';

import { setupZipcodeLookup } from "../../../src/FormDataUtil";
import { } from "../src/templates";

import national_pension from './national_pension.json';

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        national_pension
    ],
    extraSetup: (forms: DigitalForm[]) => {
        // const insuredPerson = forms[2].box("insured_person");
        // const addressField = insuredPerson.fieldInput("address");
        // setupZipcodeLookup(
        //     insuredPerson.fieldInput("zipcode"),
        //     null,
        //     addressField
        // );

        // const person = forms[2].box("person");
        // const personName = person.fieldInput("name");

        // const notificationInformation = forms[2].box("notification_information");

        // const relation = notificationInformation.fieldInput("relation");
        // const other = notificationInformation.fieldInput("other");

        // const insuredPersonName = insuredPerson.fieldInput("name");
        // function updatePersonName(){
        //     const relationValue = JSON.parse(relation.virtualInput.value);
        //     if (relationValue === "other") {
        //         personName.element.querySelector("input").value = JSON.parse(name.virtualInput.value);
        //     } else if (relationValue === "herself") {
        //         personName.element.querySelector("input").value = JSON.parse(insuredPersonName.virtualInput.value);
        //     }
        //     personName.update();
        // }

        // other.virtualInput.addEventListener("change", () => {
        //     updatePersonName();
        // });

        // const name = other.fieldInput("name");
        // name.virtualInput.addEventListener("change", () => {
        //     updatePersonName();
        // });

        // insuredPersonName.virtualInput.addEventListener("change", () => {
        //     updatePersonName();
        // });
    }
};
