// @ts-ignore
const DEBUG = __ENV__ === "development";
import './styles/main.scss';

import * as megane from '../node_modules/meganets/dist/megane.js';
import { DOMTemplate } from './denki'
import { DigitalApplicationDefinition, buildDigitalForm } from './digitalForm'
import { DigitalForm } from "./DigitalForm/DigitalForm";
import { InteractiveBindManager, DataBindManager, BindDataSource } from './bind';
import { setInterval } from 'timers';
import bowser from './bowserIncludes';

const PDF_FILE_NAME = "書類";
const PDF_META_TITLE = "書類";

export class DigitalFormEditorApplication extends megane.SinglePageApplication {
    private readonly formData: DigitalApplicationDefinition;
    private _templates;
    private _currentViewController: megane.ViewController;
    private hasData: boolean = false;
    private savedData = null;

    constructor(formData, frame, templates) {
        super(frame);
        this.formData = formData;
        this._templates = templates;
        window.addEventListener('popstate', (e) => {
            const token = location.hash.split("/");
            if (token[0] !== "#forms" && this.hasData) {
                if (isiOS) {
                    setTimeout(() => {
                        Notifier.confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE, result => {
                            if (result) {
                                this.hasData = false;
                            } else {
                                window.history.forward();
                            }
                        });
                    }, 0);
                } else {
                    if (confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE)) {
                        this.hasData = false;
                    } else {
                        window.history.forward();
                    }
                }
            }
        });
        window.addEventListener("load", () => {
            const builds = document.querySelectorAll('.debug-info');
            for (let i = 0; i < builds.length; i++) {
                // @ts-ignore
                builds[i].textContent = "build: " + __BUILD_ID__;
            }
            // app.onAppReady();
            document.body.classList.add(browser.parsePlatform().type);
        });
    }

    viewControllerForHash(hash) {
        window.scrollTo(0, 0);
        let token = hash.split("/");
        if (token[0] === "#" || token[0] === "") {
            try {
                let view = this._templates.get("acknowledgement-view");
                const viewController = new AcknowledgementViewController(view);
                viewController.onGotoFormButtonClick = (args) => {
                    this.savedData = args;
                    location.href = "#forms";
                };
                return viewController;
            } catch (e) {
                this.savedData = { _: "save" };
                location.href = "#forms";
                return;
            }
        } else if (token[0] === "#forms") {
            const viewController: FormViewController = (() => {
                if (!this.hasData) {
                    if (!DEBUG && !this.savedData) {
                        location.hash = "#";
                        return;
                    }
                    const view = this._templates.get("form-view");
                    this._currentViewController = new FormViewController(this.formData, view, this.savedData);
                    this.hasData = true;
                }
                return this._currentViewController;
            })();
            const page = (() => {
                if (token.length === 1) return 0;
                return parseInt(token[1]) - 1;
            })();
            if (viewController) {
                viewController.changeForm(page);
            }
            if (token.length === 3 && token[2] === "preview") {
                const index = token[1] + "-1";
                viewController.savePDF(true, index, false);

                const timeout = 3000;
                let start = Date.now();
                const fn = () => {
                    const height = viewController.getPDFPreviewContentHeight();
                    if (height > 0) {
                        document.body.style.height = Math.ceil(height) + "px";
                    } else if (Date.now() - start < timeout) {
                        setTimeout(fn, 200);
                    }
                };
                setTimeout(fn, 200);
            }
            return viewController;
        } else if (token[0] === "#require") {
            return new megane.ViewController(this._templates.get("requirement-view"));
        } else if (token[0] === "#others") {
            return new megane.ViewController(this._templates.get("others-view"));
        }
        return super.viewControllerForHash(hash);
    }
}

class AcknowledgementViewController extends megane.ViewController {
    constructor(view) {
        super(view);
        const paymentTypeNew = view.querySelector("[name=payment-type]");
        paymentTypeNew.checked = true;
        const agree = view.querySelector(".agree-check");
        const nodebt = view.querySelector(".no_debt-check");
        const gotoFormButton = view.querySelector(".goto-form");
        gotoFormButton.addEventListener("click", (e) => {
            e.preventDefault();
            if (!agree.checked) {
                Notifier.show("入会申込にあたっては、事前に申し込みを行う児童クラブで説明を受ける必要があります");
                return;
            }
            if (!nodebt.checked) {
                Notifier.show("放課後児童クラブ利用料の滞納（未納）がある方は、入会申込できません。\n" +
                    "こども青少年課こども育成係(学童保育利用料担当)(電話：078-322-6392)までご相談ください。");
                return;
            }
            const gotoForm = () => {
                const paymentType = view.querySelector("[name=payment-type]:checked");
                const data = {
                    "application-payment_type": paymentType.value,
                    "application-payment_id": paymentType.id,
                };
                if (!isValidBrowser) {
                    data["application-unsupported_browser"] = "△サポート外ブラウザ使用: " + systemInfo;
                }
                this.onGotoFormButtonClick(data);
            };
            if (isValidBrowser) {
                gotoForm();
            } else {
                Notifier.show(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                    if (ok) { gotoForm(); }
                });
            };
        });
    }

    didShowView(navigationController) {
        if (!isValidBrowser) {
            document.body.classList.add('unsupported');
            Notifier.show(UNSUPPORTED_BROWSER_MESSAGE);
        }
        document.body.classList.add(browser.parsePlatform().type);
    }

    public onGotoFormButtonClick: (object) => void = function () { };
}

import { DigitalFormDataSource } from "./DigitalForm/DigitalFormDataSource";

class FormViewController extends megane.ViewController {
    private readonly view;
    private readonly paperFormFrames;
    public readonly digitalFormViewController: DigitalFormViewController;
    private navButtons: HTMLElement[];
    private currentFormIndex?: number;
    private pdfPreviewWindow: PDFPreviewWindow;
    private pdfCanceled: boolean;
    private pdf;
    private needsAccountTransfer: boolean;

    private nextFormButton;
    private prevFormButton;
    private changePageButton;
    private readonly bindButton;

    private manager;
    private binder: DataBindManager;
    private isBinding: boolean = false;
    private currentScale: number = 1.0;
    private mainWindow: HTMLDivElement;

    constructor(formData: DigitalApplicationDefinition, view, data) {
        super(view);
        this.view = view;
        const allForms = [];
        const applicationWindow = view.getElementsByClassName("application-window")[0];
        const mainWindow = view.getElementsByClassName("main-content")[0];
        this.mainWindow = mainWindow;
        const nav = mainWindow.querySelector("nav");
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const form = formData.forms[i];
            const rendering = formData.forms[i].rendering;
            const iframe = document.createElement("iframe");
            iframe.setAttribute("sandbox", "allow-modals allow-same-origin allow-scripts");
            iframe.setAttribute("style", "display: none;");
            iframe.setAttribute("data-num-pages", String(rendering.numberOfPages));
            iframe.src = rendering.path;
            applicationWindow.appendChild(iframe);
            allForms.push(iframe);

            const item = document.createElement("button");
            item.setAttribute("data-form", String(i + 1));
            item.setAttribute("role", "tab");
            item.setAttribute("aria-selected", i === 0 ? "true" : "false");
            if (i === 0) item.setAttribute("selected", "true");
            item.textContent = `${i + 1}. ${form.title}`;
            nav.appendChild(item);
        }
        this.paperFormFrames = allForms;

        this.needsAccountTransfer =
            !(data &&
                (data["application-payment_id"] === "bycache" ||
                    data["application-payment_id"] === "no"));
        if (!this.needsAccountTransfer) {
            this.paperFormFrames.splice(2, 1);
        }

        const digitalFormView = view.getElementsByClassName("main-content")[0];
        this.digitalFormViewController = new DigitalFormViewController(formData, digitalFormView, data);

        if (DEBUG) {
            const footer = view.getElementsByTagName("footer")[0];
            const button = document.createElement("button");
            button.setAttribute('debug', '');
            button.textContent = "紐付け";
            button.addEventListener("click", () => {
                if (this.isBinding) {

                } else {
                    this.isBinding = true;
                    this.changePage(-1);
                    this.prepareInteractiveBind(this.paperFormFrames[this.currentFormIndex]);
                    this.updateButtonState();
                }
            });
            footer.appendChild(button);
            this.bindButton = button;

            const exportButton = document.createElement("button");
            exportButton.setAttribute('debug', '');
            exportButton.textContent = "エクスポート";
            exportButton.addEventListener("click", () => {
                const sourceElement = document.createElement("textarea");
                document.body.appendChild(sourceElement);
                const clone = this.paperFormFrames[this.currentFormIndex].contentWindow.document.documentElement.cloneNode(true);
                const clones = clone.querySelectorAll("[data-mark=clone]");
                for (let i = 0, l = clones.length; i < l; i++) {
                    clones[i].parentNode.removeChild(clones[i]);
                }
                const htmlContent = '<!doctype html>\n' + clone.outerHTML;
                sourceElement.textContent = htmlContent.replace(/ style=""/g, "");
                const range = document.createRange();
                range.selectNodeContents(sourceElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                sourceElement.setSelectionRange(0, sourceElement.textContent.length);

                if (document.execCommand("copy", false, null)) {
                    alert("クリップボードにコピーしました");
                }
                document.body.removeChild(sourceElement);
            });
            footer.appendChild(exportButton);
        }

        const templates = document.getElementById("template");
        const previewWindow = templates.getElementsByClassName("pdf-preview-window")[0].cloneNode(true);
        this.pdfPreviewWindow = new PDFPreviewWindow(previewWindow, this.view);
        this.pdfPreviewWindow.onSave = () => {
            if (this.pdf) {
                this.pdf.save(this.getPDFFilename());
            }
        };
        this.pdfPreviewWindow.onCancel = () => {
            this.pdfCanceled = true;
            if (!this.pdfPreviewWindow.isHidden()) {
                this.pdfPreviewWindow.hide();
            }
        };
        this.pdf = null;

        Array.prototype.slice.call(view.querySelectorAll(".save-pdf")).forEach((elm) => {
            elm.addEventListener("click", (e) => {
                if (isValidBrowser) {
                    this.savePDF(true);
                } else {
                    Notifier.show(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                        if (ok) { this.savePDF(true); }
                    });
                }
            });
        });

        this.nextFormButton = view.querySelector(".next-form");
        this.nextFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex + 1) % this.paperFormFrames.length + 1);
        });

        this.prevFormButton = view.querySelector(".prev-form");
        this.prevFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex - 1) % this.paperFormFrames.length + 1);
        });

        this.changePageButton = view.querySelector(".change-page");
        this.changePageButton.addEventListener("click", (e) => {
            if (this.isBinding) {
                return;
            }
            const iframe = this.paperFormFrames[this.currentFormIndex];
            let page = +iframe.getAttribute("data-page");
            let num = +iframe.getAttribute("data-num-pages");
            this.changePage(page >= num ? 1 : (page + 1));
        });

        // Navigation buton
        const buttons = view.querySelectorAll("nav > button");
        this.navButtons = [];
        for (let i = 0; i < buttons.length; ++i) {
            const button = buttons[i];
            if (this.paperFormFrames.indexOf(allForms[i]) === -1) {
                button.style.display = "none";
            } else {
                button.addEventListener("click", () => {
                    location.hash = '#forms/' + button.getAttribute("data-form");
                });
                this.navButtons.push(button);
            }
        }
        //雑に拡大縮小を実装した
        //iframeにtransformかけるとめんどくさいと思い中身を直接transformかける野蛮方式
        // 課題1. フォームをきりかえるたびにscaleをリセットするか現状のscaleにする
        // 課題2. 初回起動時にiframeの幅に合わせて拡大縮小する
        const zoom_button = view.querySelector("#zoom");
        zoom_button.addEventListener('click', (e) => {
            if (this.currentScale < 5.0) {
                this.currentScale += 0.1;
                this.updateScale();
            }
        });
        const shrink_button = view.querySelector("#shrink");
        shrink_button.addEventListener('click', (e) => {
            if (this.currentScale > 0.1) {
                this.currentScale -= 0.1;
                this.updateScale();
            }
        });
    }

    updateScale() {
        this.paperFormFrames[this.currentFormIndex].contentWindow.document.body.style.transform = "scale( " + this.currentScale + " )";
    }

    waitForIframe(iframe, callback) {
        const fn = () => {
            iframe.removeEventListener("load", fn);
            callback(iframe);
        };
        iframe.addEventListener("load", fn);
    }

    updateFields(paperFormFrame, digitalForm) {
        this.binder = new DataBindManager(__GOOGLE_MAP_API_KEY__);
        const errors = [];
        const spans = paperFormFrame.contentWindow.document.querySelectorAll("[data-bind]");
        for (let i = 0, l = spans.length; i < l; i++) {
            const dst = spans[i];
            const bind = dst.getAttribute('data-bind');
            if (bind) {
                try {
                    const dataSource = new DigitalFormDataSource(digitalForm, bind);
                    this.binder.bind(dataSource, dst);
                } catch (e) {
                    errors.push("data-id not found: " + bind);
                }
            }
        }
        if (errors.length > 0) console.error('bind error', errors);
    }

    bindFields(paperFormFrame, digitalForm) {
        this.waitForIframe(paperFormFrame, () => {
            this.updateFields(paperFormFrame, digitalForm);
        });
    }

    // PDF
    savePDF(needsPreview: boolean = true, indices = null, needsCheckList: boolean = false) {
        const sourceIndices = this.parsePDFPreviewIndices(indices);
        if (sourceIndices.length === 0) {
            alert("無効なフォーム番号が指定されました。 (" + indices + ")");
            return;
        }
        const sources = sourceIndices.map((index) => {
            return {
                elm: this.paperFormFrames[index.form - 1],
                index
            };
        });

        const pages = [];
        let numPages = sources.length + (needsCheckList ? 1 : 0);

        const onLoad = (canvas) => {
            if (this.pdfCanceled) {
                return;
            }

            if (canvas) {
                pages.push(canvas);
            }

            this.pdfPreviewWindow.setProgress(pages.length, numPages);

            if (pages.length < sources.length) {
                const source = sources[pages.length];
                this.iframe2canvas(source.elm, source.index, onLoad);
                return;
            }

            if (needsCheckList) {
                needsCheckList = false;
                const iframe = this.view.getElementsByClassName("form-checklist")[0] as HTMLIFrameElement;
                const pageIndex = this.needsAccountTransfer ? 1 : 2;
                this.iframe2canvas(iframe, new PDFPreviewIndexSet(0, pageIndex), onLoad);
                return;
            }

            // make PDF
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "in",
                format: "a4",
            });

            doc.setProperties({
                title: PDF_META_TITLE,
                creator: __BUILD_ID__,
            });

            pages.forEach((page, i) => {
                if (page.width <= page.height) {
                    doc.addImage(page, "JPEG", 0, 0, 21.0 / 2.54, 29.7 / 2.54);
                } else {
                    // rotate 90deg
                    doc.addImage(page, "JPEG", 0, -21.0 / 2.54, 29.7 / 2.54, 21.0 / 2.54, null, null, 270);
                }
                if (i !== pages.length - 1) {
                    doc.addPage();
                }
            });

            this.pdf = doc;

            if (needsPreview) {
                pages.forEach((page) => {
                    this.pdfPreviewWindow.appendPreview(page);
                });
                this.pdfPreviewWindow.showPreview();
            } else {
                this.pdfPreviewWindow.hide();
                this.pdf.save(this.getPDFFilename());
            }
        };
        this.pdf = null;
        this.pdfCanceled = false;
        this.pdfPreviewWindow.showProgress();
        onLoad(null);
    }

    parsePDFPreviewIndices(indices): PDFPreviewIndexSet[] {
        if (indices === null || indices === undefined) {
            return this.paperFormFrames.reduce((result, elm, i) => {
                const form = i + 1;
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }, []);
        }
        if (Array.isArray(indices)) {
            return indices.reduce((result, index) => {
                if (index !== null && index !== undefined) {
                    const tmp = this.parsePDFPreviewIndices(index);
                    result = result.concat(tmp);
                }
                return result;
            }, []);
        }
        const form = +indices;
        if (!isNaN(form)) {
            if (form >= 1 && form <= this.paperFormFrames.length) {
                const elm = this.paperFormFrames[form - 1];
                const result = [];
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }
            return [];
        }
        if (typeof (indices) === "string") {
            const pair = indices.split("-");
            if (pair.length === 2) {
                const form = +pair[0];
                const page = +pair[1];
                if (!isNaN(page) && !isNaN(form) && form >= 1 && form <= this.paperFormFrames.length) {
                    const elm = this.paperFormFrames[form - 1];
                    const num = +elm.getAttribute("data-num-pages");
                    if (page >= 1 && page <= num) {
                        return [new PDFPreviewIndexSet(form, page)];
                    }
                }
            }
            return [];
        }
        return [];
    }

    getPDFFilename() {
        return `${PDF_FILE_NAME}${DEBUG ? '-' + Date.now() : ''}.pdf`;
    }

    getPDFPreviewContentHeight() {
        return this.pdfPreviewWindow.getContentHeight();
    }

    isHorizontalContent(iframe) {
        return iframe.contentWindow.document.querySelector('body > .horizontal') !== null;
    }

    iframe2canvas(iframe, index: PDFPreviewIndexSet, callback) {
        const width = 1240;
        const height = 1754;

        const form = index.form;
        const page = index.page;
        const container = this.view.querySelector(".hidden");
        const horizontal = this.isHorizontalContent(iframe);
        const cloned = iframe.cloneNode(true);
        container.appendChild(cloned);
        cloned.style.display = "block";
        cloned.style.width = width + "px",
            cloned.style.height = height + "px";
        cloned.setAttribute("src", iframe.src);
        this.waitForIframe(cloned, () => {
            // apply input
            cloned.contentWindow.document.body.innerHTML = iframe.contentWindow.document.body.innerHTML;
            this.updateFields(cloned, this.digitalFormViewController.digitalForms[form - 1]);
            this.changePage(page, cloned);
            // html to canvas
            html2canvas(cloned.contentWindow.document.body, {
                width: horizontal ? height : width,
                height: horizontal ? width : height,
                scale: 1,
                backgroundColor: "#ffffff",
                useCORS: true,
                onclone: isiPhone ? this.onIFrameClone : null,
                logging: DEBUG,
            }).then((canvas) => {
                if (cloned.parentNode) {
                    cloned.parentNode.removeChild(cloned);
                }
                canvas.style.width = horizontal ? "85vw" : "60vw";
                canvas.style.height = null;
                if (callback) {
                    callback(canvas);
                }
            }).catch((err) => {
                console.log(err);
                alert("プレビューの作成中に問題が発生しました。もう一度実行してください。 (" + err + ")");
                this.pdfPreviewWindow.hide();
            });
        });
    }

    onIFrameClone(doc) {
        Array.prototype.slice.call(doc.querySelectorAll("[data-fs]")).forEach((elm) => {
            for (let i = 5; i <= 18; ++i) {
                elm.classList.remove("pt" + i);
            }
            var fontSize = elm.getAttribute("data-fs");
            if (fontSize.startsWith("pt")) {
                elm.classList.add(fontSize);
            } else {
                const ppi = 150;
                const zoom = 0.87;
                var point = +fontSize;
                if (!isNaN(point)) {
                    elm.style.fontSize = `calc(${point} / 72 * ${ppi} * ${zoom} * 0.08vw)`;
                }
            }
        });
    }

    changeForm(formIndex) {
        if (this.currentFormIndex === formIndex) {
            const iframe = this.paperFormFrames[this.currentFormIndex];
            const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
            this.bindFields(iframe, digitalForm);
            return;
        }
        if (this.currentFormIndex !== undefined) {
            const lastFrame = this.paperFormFrames[this.currentFormIndex];
            lastFrame.style.display = "none";
            this.digitalFormViewController.selectedIndex = -1;
        }
        this.currentFormIndex = formIndex;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        iframe.style.display = "block";
        const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.digitalFormViewController.selectedIndex = this.currentFormIndex;
        this.bindFields(iframe, digitalForm);
        this.changePageSafe(1);
        this.updateButtonState();
    }

    changePageSafe(pageNumber: number) {
        const paperFormFrame = this.paperFormFrames[this.currentFormIndex];
        const formIndex = this.currentFormIndex;
        const fn = () => {
            paperFormFrame.removeEventListener("load", fn);
            if (this.currentFormIndex === formIndex) {
                this.changePage(pageNumber)
            }
        };
        paperFormFrame.addEventListener("load", fn);
    }

    changePage(pageNumber: number, iframe: HTMLIFrameElement = null) {
        iframe = iframe || this.paperFormFrames[this.currentFormIndex];
        iframe.setAttribute("data-page", String(pageNumber));
        for (let i = 1; i <= 3; ++i) {
            const hidden = i !== +pageNumber;
            const elms = iframe.contentWindow.document.getElementsByClassName("only-" + i);
            Array.prototype.forEach.call(elms, (elm) => {
                if (pageNumber === -1) {
                    elm.removeAttribute("data-display");
                    elm.style.display = null;
                    elm.removeAttribute("style");
                } else {
                    if (!elm.getAttribute("data-display")) {
                        var style = document.defaultView.getComputedStyle(elm);
                        elm.setAttribute("data-display", style.display);
                    }
                    elm.style.display = hidden ? "none" : elm.getAttribute("data-display");
                }
            });
        }
        // あえて一瞬待つことで上の変更を反映させる
        setTimeout(() => {
            this.currentScale = this.mainWindow.offsetLeft / iframe.contentWindow.document.body.scrollWidth;
            this.updateScale();
        });
    }

    updateButtonState() {
        this.nextFormButton.disabled = this.isBinding || this.currentFormIndex === this.paperFormFrames.length - 1;
        this.prevFormButton.disabled = this.isBinding || this.currentFormIndex === 0;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        let num = +iframe.getAttribute("data-num-pages");
        this.changePageButton.disabled = this.isBinding || num <= 1;
        if (this.bindButton) {
            this.bindButton.disabled = this.isBinding;
        }
        for (let j = 0; j < this.navButtons.length; ++j) {
            const button = this.navButtons[j];
            if (j === this.currentFormIndex) {
                button.setAttribute("selected", "");
                button.setAttribute("aria-selected", "true");
            } else {
                button.removeAttribute("selected");
                button.setAttribute("aria-selected", "false");
            }
        }
    }
}

class DigitalFormViewController {
    public readonly view: HTMLElement;
    public readonly digitalForms: DigitalForm[] = [];
    private titles: string[] = [];
    private titleElement: HTMLElement;
    private container: HTMLElement;
    private index: number;

    constructor(formData: DigitalApplicationDefinition, view: HTMLElement, data) {
        this.view = view;
        this.titleElement = this.view.querySelector("[data-role=title]") as HTMLElement;
        this.container = view.getElementsByTagName("MAIN")[0] as HTMLElement;
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const formDefinition = formData.forms[i];
            const digitalForm = buildDigitalForm(
                formDefinition,
                new DOMTemplate(document.getElementById("template"))
            );
            digitalForm.data = data;
            this.digitalForms.push(digitalForm);
            this.titles.push(formDefinition.title);
        }
        if (formData.extraSetup) {
            formData.extraSetup(this.digitalForms);
        }
        this.index = -1;
    }
    //
    // decodeQueryData(){
    //     const queryString = location.search;
    //     const dataString = /data=([^&]*)$/.exec(queryString);
    //     if(dataString){
    //         const jsonString = decodeURIComponent(dataString[1]);
    //         try{
    //             return JSON.parse(jsonString);
    //         }catch(e){
    //         }
    //     }
    //     return {};
    // }

    get selectedIndex(): number {
        return this.index;
    }

    set selectedIndex(value: number) {
        const children = this.container.children;
        for (let i = 0; i < children.length; ++i) {
            this.container.removeChild(children[i]);
        }
        if (value >= 0 && value < this.digitalForms.length) {
            this.container.appendChild(this.digitalForms[value].element);
            this.titleElement.textContent = this.titles[value];
            this.index = value;
        } else {
            this.index = -1;
        }
    }
}

class PDFPreviewIndexSet {
    public form: number;
    public page: number;

    constructor(form: number, page: number) {
        this.form = form;
        this.page = page;
    }
}

class PDFPreviewWindow {
    private view: HTMLElement;
    private container: HTMLElement;
    private progressView: Element;
    private progressBar: Element;
    private previewView: Element;
    private previewFrame: HTMLIFrameElement;
    public onSave: Function;
    public onCancel: Function;

    constructor(view, container) {
        this.view = view;
        this.container = container;
        this.progressView = this.view.getElementsByClassName("pdf-progress")[0];
        this.progressBar = this.progressView.getElementsByClassName("pdf-progress-bar")[0];
        this.previewView = this.view.getElementsByClassName("pdf-preview")[0];
        this.previewFrame = this.previewView.getElementsByTagName("IFRAME")[0] as HTMLIFrameElement;

        if (isiOS) {
            this.view.addEventListener("touchmove", this.preventScroll, false);
        }

        const cancelButton = this.view.getElementsByClassName("pdf-progress-cancel")[0];
        cancelButton.addEventListener("click", (e) => {
            if (this.onCancel) {
                this.onCancel();
            }
            e.preventDefault();
            return false;
        });

        const saveButton = this.view.getElementsByClassName("pdf-preview-save")[0];
        saveButton.addEventListener("click", () => {
            if (this.onSave) { this.onSave(); }
        });
        const closeButton = this.view.getElementsByClassName("pdf-preview-close")[0];
        closeButton.addEventListener("click", () => {
            this.hide();
        });

        this.saveStyle(this.progressView);
        this.saveStyle(this.previewView);
    }

    getContentHeight(): number {
        const doc = this.previewFrame.contentWindow.document;
        const elm = doc.getElementById("container");
        if (!elm) { return 0; }
        const style = doc.defaultView.getComputedStyle(elm);
        const height = +(style.height.replace("px", ""));
        return isNaN(height) ? 0 : height;
    }

    isHidden(): boolean {
        return this.view.parentNode === null;
    }

    show() {
        if (this.isHidden()) {
            this.container.appendChild(this.view);
        }
    }

    hide() {
        if (!this.isHidden()) {
            this.container.removeChild(this.view);
        }
        this.hideProgress();
        this.hidePreview();
        const images = Array.prototype.slice.call(this.previewView.getElementsByClassName("pdf-preview-item"));
        images.forEach((elm) => {
            elm.parentNode.removeChild(elm);
        });
    }

    showProgress() {
        this.show();
        this.restoreStyle(this.progressView);
        this.previewView.style.display = "none";
    }

    hideProgress() {
        this.progressView.style.display = "none";
    }

    showPreview() {
        this.show();
        this.progressView.style.display = "none";
        this.restoreStyle(this.previewView);
        this.view.querySelector('.pdf-preview-menu').classList.remove('hidden');
    }

    hidePreview() {
        this.previewView.style.display = "none";
        this.view.querySelector('.pdf-preview-menu').classList.add('hidden');
    }

    saveStyle(elm) {
        var style = document.defaultView.getComputedStyle(elm);
        elm.setAttribute("data-display", style.display);
    }

    restoreStyle(elm) {
        const display = elm.getAttribute("data-display");
        elm.style.display = display;
    }

    setProgress(currentValue: number, maxValue: number) {
        const progress = Math.max(0, Math.min(currentValue / maxValue * 100, 100));
        this.progressBar.style.width = progress + "%";
    }

    appendPreview(content: HTMLElement) {
        const template = document.getElementById("template");
        const item = template.getElementsByClassName("pdf-preview-item")[0].cloneNode(true);
        item.appendChild(content);
        const container = this.previewFrame.contentWindow.document.getElementById("container");
        container.appendChild(item);
    }

    private preventScroll(e) {
        e.preventDefault();
    }
}

class Notifier {

    public readonly alertbox: HTMLElement;
    public readonly lightbox: HTMLElement;
    public readonly button: HTMLElement;
    public readonly cancelButton: HTMLElement;
    private callback?: (boolean) => void;

    private constructor(message: string, template: HTMLElement, callback: (boolean) => void = null) {
        this.alertbox = template.cloneNode(true);
        this.alertbox.addEventListener('touchmove', this.preventScroll, false);

        this.callback = callback;

        const messagebox = this.alertbox.querySelector('p');
        messagebox.textContent = message;

        this.lightbox = document.createElement('div');
        this.lightbox.setAttribute('class', 'lightbox');
        this.lightbox.setAttribute('style', `height:${document.body.scrollHeight + 'px'}`);
        this.lightbox.addEventListener('touchmove', this.preventScroll, false);

        this.button = this.alertbox.querySelector('.okButton');
        this.button.addEventListener('click', (e) => {
            const callback = this.callback;
            this.callback = null;
            this.close();
            if (callback) { callback(true); }
        });

        const cancelButton = this.alertbox.querySelector('.cancelButton');
        if (cancelButton) {
            cancelButton.addEventListener('click', e => {
                const callback = this.callback;
                this.callback = null;
                this.close();
                if (callback) { callback(false); }
            });
        }

        document.body.insertBefore(this.lightbox, document.body.firstChild);
        document.body.appendChild(this.alertbox);
    }

    static show(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.alert");
        return new Notifier(message, template, callback);
    }

    static confirm(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm");
        return new Notifier(message, template, callback);
    }

    preventScroll(e) {
        e.preventDefault();
    }

    close() {
        this.alertbox.parentNode.removeChild(this.alertbox);
        this.lightbox.removeEventListener('touchmove', this.preventScroll, false);
        this.lightbox.parentNode.removeChild(this.lightbox);
        if (this.callback) {
            this.callback(false);
            this.callback = null;
        }
    }
}


/*Bowser*/
const browser = bowser.getParser(window.navigator.userAgent);
// console.log(browser.parse());
const isValidBrowser = browser.satisfies({
    windows: {
        "internet explorer": ">9",
        "microsoft edge": ">15",
        firefox: ">61",
        chrome: ">50"
    },
    macos: {
        safari: ">10",
        firefox: ">61",
        chrome: ">50"
    },
    mobile: {
        safari: '>9',
        chrome: ">50"
    },
    tablet: {
        safari: '>9',
        chrome: ">50"
    }
});
// const isiOS = browser.satisfies({
//     mobile: {
//         safari: '>9'
//     },
//     tablet: {
//         safari: '>9'
//     }
// });

const osInfo = browser.parseOS();
const browserInfo = browser.getBrowser();
const isiOS = osInfo.name === 'iOS';
// console.log(isValidBrowser);
const isiPhone = isiOS && browser.getPlatform().type === 'mobile';

const systemInfo = osInfo.name + "-" + osInfo.version + " " + browserInfo.name + "-" + browserInfo.version;

const UNSUPPORTED_BROWSER_MESSAGE = "お使いのブラウザは本サービスに対応していない為、正常にご利用できない可能性があります。";
const ARE_YOU_SURE_TO_LEAVE_MESSAGE = "入力されたデータが失われますが続けますか？";
