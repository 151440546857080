function convertToASCIIDigit(str) {
    const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
    return str.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
    });
}

export interface BindDataSource {
    value: string
    focus: (HTMLElement) => void,
    addChangeListener: (func: () => void) => void
}

export class InteractiveBindManager {

    public onBind: (src: HTMLElement, dst: HTMLElement) => void;

    constructor(srcs: Array<HTMLElement>, dsts: Array<HTMLElement>) {
        let targetSpan = null;
        function deselectSpan() {
            if (targetSpan) {
                targetSpan.removeAttribute("style");
                targetSpan = null;
            }
        }

        function selectSpan(span: HTMLElement) {
            if (targetSpan !== span) {
                deselectSpan();
                targetSpan = span;
                targetSpan.style.border = "solid 2px #0000ff";
            }
        }

        let targetData = null;
        function deselectData() {
            if (targetData) {
                targetData.removeAttribute("style");
                targetData = null;
            }
        }

        function selectData(data: HTMLElement) {
            if (targetData !== this) {
                deselectData();
                targetData = data;
                data.style.backgroundColor = "#74ecff";
            }
        }

        for (let i = 0, l = srcs.length; i < l; i++) {
            let input = srcs[i];
            input.addEventListener('mousemove', (e) => {
                selectData(input);
                e.stopPropagation();
                return false;
            });
            input.addEventListener('mouseout', (e) => {
                if (targetData === input) {
                    deselectData();
                }
                e.stopPropagation();
                return false;
            });
            input.addEventListener('click', (e) => {
                if (targetSpan && targetData) {
                    this.bind(targetData, targetSpan);
                    deselectSpan();
                    deselectData();
                }
            });
        }
        for (let i = 0, l = dsts.length; i < l; i++) {
            const span = dsts[i];
            span.addEventListener('click', () => {
                selectSpan(span);
            });
        }
    }

    bind(src, dst: HTMLElement) {
        dst.setAttribute('data-bind', src.getAttribute('data-id'));
        this.onBind(src, dst);
    }
}

export class DataBindManager {
    private readonly mapApiKey: string;

    constructor(mapApiKey: string = null) {
        this.mapApiKey = mapApiKey;
    }

    updateParsedValue(parsed, dst) {
        const dataType = dst.getAttribute('data-type');
        if (dataType === 'digit') {
            if (!parsed) {
                parsed = "";
            }
            const digits = dst.querySelectorAll("[data-role=digit]");
            const alignRight = dst.getAttribute('data-digit-align') === "right";
            for (let i = 0, l = digits.length; i < l; i++) {
                if (alignRight) {
                    const n = Math.max(l - parsed.length, 0);
                    digits[i].innerHTML = i >= n ? parsed.charAt(i - n) : ' ';
                } else {
                    digits[i].innerHTML = i < parsed.length ? parsed.charAt(i) : ' ';
                }
            }
        } else if (dataType === "multiline_digit") {
            if (!parsed) {
                parsed = "";
            }
            dst.innerHTML = "";
            const digitsPerRow = parseInt(dst.getAttribute('data-digits-per-row'));
            do {
                for (let i = 0; i < digitsPerRow; i++) {
                    const div = document.createElement("div");
                    div.setAttribute('class', dst.getAttribute('data-digit-class'));
                    div.setAttribute('data-role', 'digit');
                    dst.appendChild(div);
                }
            } while (parsed.length > dst.querySelectorAll("[data-role=digit]").length);
            const digits = dst.querySelectorAll("[data-role=digit]");
            for (let i = 0, l = digits.length; i < l; i++) {
                digits[i].textContent = i < parsed.length ? parsed.charAt(i) : '';
            }
        } else if (dataType === 'fiscal_year') {
            if (parsed === "next_year") {
                dst.textContent = '31';
            } else if (parsed === "this_year") {
                dst.textContent = '30';
            }
        } else if (dataType === 'year_AD_to_Heisei') {
            const year = parseInt(parsed);
            dst.textContent = (() => {
                if (!isFinite(year)) {
                    return " ";
                }
                const heisei = year - 1988;
                if (heisei <= 0) {
                    return " ";
                }
                if (heisei === 1) return "元";
                return String(heisei);
            })();
        } else if (dataType === 'zipcode') {
            const text = parsed;
            if (text) {
                dst.textContent = (() => {
                    if (text.length < 3) {
                        return text;
                    }
                    return text.substr(0, 3) + '-' + text.substr(3);
                })();
            }
        } else if (dataType === 'map') {
            const locationInfo = parsed;
            dst.innerHTML = "";
            if (parsed) {
                dst.classList.remove("handwriting");
                const image = <HTMLImageElement>document.createElement("img");
                const query = `center=${locationInfo.center.lat},${locationInfo.center.lng}`
                    + `&zoom=${locationInfo.zoom}&size=640x400&scale=2&key=${this.mapApiKey}`
                    + `&style=saturation:-100&style=feature:poi|visibility:on`
                    + `&style=feature:transit|visibility:off`
                    + `&style=feature:administrative|visibility:on`
                    + `&style=feature:road|visibility:simplified`
                    + `&markers=size:mid|color:black||${locationInfo.marker.lat},${locationInfo.marker.lng}`;
                const url = `https://maps.googleapis.com/maps/api/staticmap?${query}`;
                image.width = 640;
                image.height = 400;
                image.setAttribute('src', url);
                dst.appendChild(image);
            } else {
                dst.classList.add("handwriting");
            }
        } else if (dataType === 'radio') {
            const targetClass = dst.getAttribute('data-target-class');
            if (dst.getAttribute('data-target-value') === parsed) {
                dst.classList.add(targetClass);
            } else {
                dst.classList.remove(targetClass);
            }
        } else if (dataType === 'checkbox') {
            const targetClass = dst.getAttribute('data-target-class');
            const target = dst.getAttribute('data-target-value');
            const operator = dst.getAttribute('data-target-operator');
            if (parsed !== null && parsed.includes(target) === (operator !== "not")) {
                dst.classList.add(targetClass);
            } else {
                dst.classList.remove(targetClass);
            }
        } else if (dataType === 'array') {
            const parent = dst.parentNode;
            if (parent) {
                const clones = parent.querySelectorAll('[data-mark=clone]');
                for (let i = 0, l = clones.length; i < l; i++) {
                    parent.removeChild(clones[i]);
                }
                let cardinality = parseInt(dst.getAttribute("data-cardinality-min"));
                if (parsed) {
                    cardinality = Math.max(cardinality, Object.keys(parsed).length);
                }
                dst.style.display = "none";
                for (let i = 0, l = cardinality; i < l; i++) {
                    const element = dst.cloneNode(true);
                    element.removeAttribute("style");
                    element.setAttribute("data-mark", "clone");
                    if (parsed && parsed[String(i)]) {
                        console.log(parsed[String(i)]);
                        const properties = element.querySelectorAll("[data-bind-property]");
                        for (let j = 0, l = properties.length; j < l; j++) {
                            const property = properties[j];
                            const key = property.getAttribute("data-bind-property");
                            this.updateParsedValue(parsed[String(i)][key], property);
                        }
                    }
                    parent.appendChild(element);
                }
            }
        } else if (dataType === "textarea") {
            const escaped = parsed.replace(/[&'`"<>]/g, function (match) {
                return {
                    '&': '&amp;',
                    "'": '&#x27;',
                    '`': '&#x60;',
                    '"': '&quot;',
                    '<': '&lt;',
                    '>': '&gt;',
                }[match]
            });
            dst.innerHTML = escaped.replace(/\n/g, "<br>");
        } else if (dataType === "char") {
            let str = String(parsed);
            if (dst.getAttribute("data-modifier") === "gengo") {
                str = str === "1" ? "元" : str;
            }
            const offset = parseInt(dst.getAttribute("data-offset"));
            const align = dst.getAttribute("data-digit-align");
            if (align === "right") {
                const length = str.length;
                if (length <= offset) {
                    dst.textContent = "";
                } else {
                    dst.textContent = str.substr(length - offset - 1, 1);
                }
            }
        } else {
            if (parsed !== undefined) {
                dst.textContent = parsed;
            } else {
                dst.textContent = "";
            }
        }
    }

    updateValue(src: BindDataSource, dst: HTMLElement) {
        const value = src.value;
        const dataType = dst.getAttribute('data-type');
        if (dataType === "raw") {
            dst.textContent = value;
            return;
        }
        if (dataType === "raw_nengo") {
            dst.textContent = (() => {
                const normalized = convertToASCIIDigit(value);
                if (normalized === "1") return "元";
                return String(normalized);
            })();
            return;
        }
        let parsed;
        try {
            if (dst.getAttribute("data-is-json") === "false") {
                parsed = value;
            } else {
                parsed = JSON.parse(value);
            }
        } catch (e) {
            console.error("failed to get value", src);
        }
        this.updateParsedValue(parsed, dst);
    }

    bind(src: BindDataSource, dst: HTMLElement) {
        const updateValue = () => {
            this.updateValue(src, dst);
        };

        src.addChangeListener(updateValue);
        dst.addEventListener('click', () => {
            src.focus(dst);
        });

        updateValue();
    }
}
