import { DOMTemplate } from "./denki";
import { FieldBox } from "./DigitalForm/FieldBox";
import { DigitalForm } from "./DigitalForm/DigitalForm";

export interface FormElementInterface {
    id?: string,
    title?: string,
    description?: string,
    required?: boolean
}

export interface ElementInterface {
    id?: string,
    title?: string,
    description?: string,
    required?: boolean,
    context?: any,
    hidden?: boolean,
    condition?: string,
    cardinality?: Cardinality,
}

export interface PrefixSuffixInterface {
    prefix: string,
    suffix: string
}

export type Cardinality = {
    min?: number,
    max?: number,
} | number | string;

export interface TextElementDefinition extends ElementInterface {
    kind: "text",
    id: string,
    prefix?: string,
    suffix?: string,
    placeholder?: string,
    min?: number,
    max?: number,
}

export interface OptionType {
    label: string,
    value: string
}

export interface RadioElementDefinition extends ElementInterface {
    kind: "radio",
    id: string,
    options: OptionType[]
}

export interface CheckBoxElementDefinition extends ElementInterface {
    kind: "checkbox",
    id: string,
    options: OptionType[]
}

export interface SelectElementDefinition extends ElementInterface {
    kind: "select",
    id: string,
    options: OptionType[],
    initialValue?: string
}

export interface NumberElementDefinition extends ElementInterface {
    kind: "number",
    id: string,
    prefix?: string,
    suffix?: string,
    min?: number,
    max?: number
    initialValue?: number
}

export interface TextAreaElementDefinition extends ElementInterface {
    kind: "textarea",
    id: string,
    placeholder?: string
}

export type ElementDefinition = TextElementDefinition | RadioElementDefinition | CheckBoxElementDefinition | SelectElementDefinition | NumberElementDefinition | TextAreaElementDefinition;

export interface TemplateReference extends ElementInterface {
    kind: "template",
    template: string,
    condition?: string,
    cardinality?: Cardinality,
    extraSetup?: (FieldInputElement) => void
    hidden?: boolean
}

export interface FieldObjectDefinition extends ElementInterface {
    kind: "field",
    id: string,
    fields: FieldElementDefinition[],
    value?: (element: FieldCompositeElement) => string,
    focus?: (element: FieldCompositeElement) => void,
    setup?: (element: FieldCompositeElement, definition?: FieldObjectDefinition) => void,
    condition?: string,
    cardinality?: Cardinality,
    extraSetup?: (element: FieldCompositeElement, definition?: FieldObjectDefinition) => void
    hidden?: boolean
}

type ValidatorMap = { [key: string]: (value: string) => string[] }
export interface FieldDomTemplate extends ElementInterface {
    kind: "dom",
    domTemplate: string,
    value?: (element: FieldDomElement) => string,
    focus?: () => void
    validate?: ((element: FieldDomElement) => string[]) | ValidatorMap,
    setup?: (element: FieldDomElement, definition?: FieldDomTemplate) => void,
    condition?: string,
    cardinality?: Cardinality,
    manualUpdate?: (element: FieldDomElement) => void,
    extraSetup?: (element: FieldDomElement, definition?: FieldDomTemplate) => void
}

export interface FieldObjectTemplate {
    kind: "field",
    fields: FieldElementDefinition[],
    extraSetup?: (FieldInputElement) => void
}

export type FieldElementDefinition =
    ElementDefinition
    | TemplateReference
    | FieldObjectDefinition
    | FieldDomTemplate;

export type FieldTemplate = FieldDomTemplate | FieldObjectTemplate;

export interface DigitalFormBoxDefinition {
    id: string,
    title?: string,
    description?: string,
    required?: boolean,
    hidden?: boolean,
    elements: FieldElementDefinition[]
}

export interface FormRenderingSetting {
    path: string,
    numberOfPages: number
}

export interface DigitalFormDefinition {
    title: string,
    rendering: FormRenderingSetting
    boxes: DigitalFormBoxDefinition[],
    extraSetup?: (form: DigitalForm) => void
}

export interface DigitalApplicationDefinition {
    forms: DigitalFormDefinition[],
    extraSetup?: (forms: DigitalForm[]) => void,
}

const templates = {};
export const TemplateManager = {
    getTemplate: function (name): FieldElementDefinition {
        const template = templates[name];
        if (template) {
            return Object.assign({ id: name }, template);
        }
        throw new Error("undefined field box template: " + name);
    },
    addTemplate: function (name, data) {
        templates[name] = data;
    },
    addTemplates: function (definitions) {
        for (let i in definitions) {
            if (!definitions.hasOwnProperty(i)) continue;
            templates[i] = definitions[i];
        }
    },
    startsWithJapaneseMultibyteCharacter: function (str) {
        return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
    },
    isKatakanaString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[ァ-ヶー]+$/.exec(str);
    },
    isNumberString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[0-9０-９]+$/.exec(str);
    },
    createValidatorStartWithJapaneseCharacter: function () {
        const self = this;
        return function () {
            const errors = [];
            if (!self.startsWithJapaneseMultibyteCharacter(JSON.parse(this.value))) {
                errors.push("正しく入力してください");
            }
            return errors;
        };
    },
    Util: {
        startsWithJapaneseMultibyteCharacter: function (str) {
            return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
        },
        isKatakanaString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[ァ-ヶー]+$/.exec(str);
        },
        isNumberString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[0-9０-９]+$/.exec(str);
        },
        createValidatorStartWithJapaneseCharacter: function () {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push("正しく入力してください");
                }
                return errors;
            };
        },
        createValidatorNotEmptyStartWithJapaneseCharacter: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!value) {
                    errors.push(`${label}を入力してください`)
                }
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push(`${label}を正しく入力してください`);
                }
                return errors;
            };
        },
        createValidatorKatakana: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isKatakanaString(value)) {
                    errors.push(`${label}をカタカナで入力してください`);
                }
                return errors;
            };
        },
        createValidatorDigit: function (label = "", length = 0) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isNumberString(value) || (length > 0 && value.length !== length)) {
                    if (label && length > 0) {
                        errors.push(`${label}${length}ケタを数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を数字で入力してください`);
                    } else {
                        if (length > 0) {
                            errors.push(`${length}ケタの数字で入力してください`);
                        } else {
                            errors.push(`数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        convertToASCIIDigit(str) {
            const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
            return str.replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
            });
        }
    }
};

import { FieldInputElement } from "./DigitalForm/Element/FieldInputElement";
import { FormTextElement } from "./DigitalForm/Element/FormTextElement";
import { FormRadioElement } from "./DigitalForm/Element/FormRadioElement";
import { FormSelectElement } from "./DigitalForm/Element/FormSelectElement";
import { FormNumberElement } from "./DigitalForm/Element/FormNumberElement";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";
import { FieldCompositeElement } from "./DigitalForm/FieldCompositeElement"
import { FormElement } from "./DigitalForm/Element/FormElement";
import { FormTextAreaElement } from "./DigitalForm/Element/FormTextAreaElement";
import { FormCheckBoxElement } from "./DigitalForm/Element/FormCheckBoxElement";

function setupFormElement(definition: ElementInterface, element: FormElement) {
    if (definition.title) {
        element.title = definition.title;
    }
    if (definition.description) {
        element.description = definition.description;
    }
    if (definition.required) {
        element.required = definition.required;
    }
}

function setupPreSuffix(definition: TextElementDefinition | NumberElementDefinition, element: FormTextElement | FormNumberElement) {
    if (definition.prefix) {
        element.prefixElement.textContent = definition.prefix;
    }
    if (definition.suffix) {
        element.suffixElement.textContent = definition.suffix;
    }
}

function buildTextElement(definition: TextElementDefinition, context, template: DOMTemplate): FormTextElement {
    const element = new FormTextElement(context.id, definition.id, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.min !== undefined) {
        element.textElement.minLength = definition.min;
    }
    if (definition.max !== undefined) {
        element.textElement.maxLength = definition.max;
    }
    if (definition.placeholder) {
        element.textElement.placeholder = definition.placeholder;
    }
    return element;
}

function buildRadioElement(definition: RadioElementDefinition, context, template: DOMTemplate): FormRadioElement {
    const element = new FormRadioElement(context.id, definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildCheckBoxElement(definition: CheckBoxElementDefinition, context, template: DOMTemplate): FormCheckBoxElement {
    const element = new FormCheckBoxElement(context.id, definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildSelectElement(definition: SelectElementDefinition, context, template: DOMTemplate): FormSelectElement {
    const element = new FormSelectElement(context.id, definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildNumberElement(definition: NumberElementDefinition, context, template: DOMTemplate): FormNumberElement {
    const element = new FormNumberElement(context.id, definition.id, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.min !== undefined) {
        element.min = definition.min;
    }
    if (definition.max !== undefined) {
        element.max = definition.max;
    }
    if (definition.initialValue) {
        element.initialValue = definition.initialValue;
    }
    return element;
}

function buildTextAreaElement(definition: TextAreaElementDefinition, context, template: DOMTemplate): FormTextAreaElement {
    const element = new FormTextAreaElement(context.id, definition.id, template);
    setupFormElement(definition, element);
    if (definition.placeholder) {
        element.textAreaElement.placeholder = definition.placeholder;
    }
    return element;
}

function buildFieldDomElement(definition: FieldDomTemplate, context, template: DOMTemplate): FieldDomElement {
    const element = new FieldDomElement(context.id, definition.id, definition.domTemplate, context.box, template, context.required);
    element.validator = definition.validate;
    element.calculateValue = definition.value;
    setupFormElement(definition, element);
    if (definition.manualUpdate) {
        definition.manualUpdate(element);
    } else {
        element.mapKeys.forEach(key => {
            element.registerInput(element.subInput(key));
        });
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

function buildFieldCompositeElement(definition: FieldObjectDefinition, context, template: DOMTemplate): FieldCompositeElement {
    const element = new FieldCompositeElement(context.id, definition.id, context.box, template, context.required);
    element.calculateValue = definition.value;
    setupFormElement(definition, element);
    const fields = definition.fields;
    for (let i = 0, l = fields.length; i < l; i++) {
        const field = fields[i];
        const subContext = {
            id: element.id,
            required: context.required || field.required,
            box: context.box
        };
        const subInput = createFieldInputElements(field, subContext, template);
        element.addElement(subInput);
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

export function createFieldInputElements(definition: FieldElementDefinition, context, template: DOMTemplate): FieldInputElement {
    switch (definition.kind) {
        case "template":
            const subDefinition: FieldElementDefinition = TemplateManager.getTemplate(definition.template);
            const copy = Object.assign({}, definition);
            delete copy.template;
            delete copy.kind;
            Object.assign(subDefinition, copy);
            return createFieldInputElements(subDefinition, context, template);
        case "field":
            return buildFieldCompositeElement(definition, context, template);
        case "text":
            return buildTextElement(definition, context, template);
        case "radio":
            return buildRadioElement(definition, context, template);
        case "checkbox":
            return buildCheckBoxElement(definition, context, template);
        case "select":
            return buildSelectElement(definition, context, template);
        case "number":
            return buildNumberElement(definition, context, template);
        case "textarea":
            return buildTextAreaElement(definition, context, template);
        case "dom":
            return buildFieldDomElement(definition, context, template);
    }
}

export function buildInput(field: FieldElementDefinition, box: FieldBox, template: DOMTemplate): FieldInputElement {
    let min = 1;
    let max = 1;
    if (field.cardinality) {
        if (typeof field.cardinality === "number") {
            min = field.cardinality;
            max = field.cardinality;
        } else if (typeof field.cardinality === "string") {
            const bind = field.cardinality;
            const fieldInput = box.fieldInput(bind);
            const value = fieldInput.virtualInput.value;
            if (value !== "undefined") {
                min = JSON.parse(value);
            } else {
                min = 1;
            }

            max = min;
            const callback = () => {
                const input = buildInput(field, box, template);
                box.updateFieldInput(input, field.id);
                fieldInput.virtualInput.removeEventListener("change", callback);
            };
            fieldInput.virtualInput.addEventListener("change", callback);
        } else {
            if (field.cardinality.min) {
                min = field.cardinality.min;
            }
            if (field.cardinality.max) {
                max = field.cardinality.max;
            }
        }
    }
    const definition = (() => {
        if (max === 1) return field;
        const fieldDefinition: FieldObjectDefinition = {
            kind: "field",
            id: field.id,
            fields: []
        };
        for (let j = 0; j < min; j++) {
            const elementField = Object.assign({}, field);
            elementField.id = j;
            fieldDefinition.fields.push(elementField);
        }
        return fieldDefinition;
    })();
    const fieldInput = createFieldInputElements(definition, { id: box.id, box }, template);
    if (field.condition) {
        const bind = field.condition;
        const targetInput = box.fieldInput(bind);
        const callback = () => {
            const value = targetInput.virtualInput.value;
            const parsed = JSON.parse(value);
            if (targetInput.definition.type === "checkbox") {
                fieldInput.disabled = fieldInput.hidden = !parsed.includes(field.id);
            } else if (targetInput.definition.type === "radio") {
                fieldInput.disabled = fieldInput.hidden = parsed !== field.id;
            } else {
                throw new Error("unsupported condition");
            }
        };
        targetInput.virtualInput.addEventListener("change", callback);
        callback();
    }
    if (field.hidden) {
        fieldInput.hidden = true;
    }
    return fieldInput;
}

export function buildFieldBox(data: DigitalFormBoxDefinition, template: DOMTemplate): FieldBox {
    const box = new FieldBox(data.id, template);
    box.title = data.title;
    if (data.required) {
        box.isRequired = true;
    }
    if (data.description) {
        box.description = data.description;
    }
    for (let i = 0, l = data.elements.length; i < l; i++) {
        const field = data.elements[i];
        const input = buildInput(field, box, template)
        box.appendFieldInput(input);
    }
    return box;
}

export function buildDigitalForm(data: DigitalFormDefinition, template: DOMTemplate): DigitalForm {
    const form = new DigitalForm(template);
    if (data.title) {
        form.title = data.title;
    }
    for (let i = 0, l = data.boxes.length; i < l; i++) {
        const definition = data.boxes[i];
        const box = buildFieldBox(definition, template);
        if (definition.hidden) {
            box.element.style.display = "none";
        }
        form.appendBox(box);
    }
    if (form.numberOfBoxes > 0) {
        form.boxAtIndex(0).open();
    }
    return form;
}