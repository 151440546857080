import { FieldElementDefinition, TemplateManager } from "./digitalForm";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";

export const TemplateUtil = TemplateManager.Util;

const templates: { [s: string]: FieldElementDefinition } = {
    name: {
        kind: "dom",
        domTemplate: "input_name",
        value: (element: FieldDomElement) => {
            const familyName = <HTMLInputElement>element.subInput("family_name");
            const firstName = <HTMLInputElement>element.subInput("first_name");
            return JSON.stringify(familyName.value + "　" + firstName.value);
        },
        validate: {
            family_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter("姓"),
            first_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter("名")
        }
    },
    name_kanji_alphabet: {
        kind: "dom",
        domTemplate: "input_name_kanji_alphabet",
        value: (element: FieldDomElement) => {
            return JSON.stringify(element.subInput("family_name").value + "　" + element.subInput("first_name").value);
        },
        validate: {
        }
    },
    name_kana: {
        kind: "dom",
        domTemplate: "input_name_kana",
        value: (element: FieldDomElement) => {
            return JSON.stringify(element.subInput("family_name").value + "　" + element.subInput("first_name").value);
        },
        validate: {
            family_name: TemplateUtil.createValidatorKatakana("セイ"),
            first_name: TemplateUtil.createValidatorKatakana("メイ")
        }
    },
    age: {
        kind: "dom",
        domTemplate: "input_age",
        value: function (element: FieldDomElement) {
            return JSON.stringify(element.subInput("age").value);
        },
        validate: {
            age: TemplateUtil.createValidatorDigit()
        }
    },
    zipcode: {
        kind: "dom",
        domTemplate: "input_zipcode",
        value: function (element: FieldDomElement) {
            return JSON.stringify(
                TemplateUtil.convertToASCIIDigit(element.subInput("XXX-____").value) +
                TemplateUtil.convertToASCIIDigit(element.subInput("___-XXXX").value)
            );
        },
        validate: {
            "XXX-____": TemplateUtil.createValidatorDigit("上", 3),
            "___-XXXX": TemplateUtil.createValidatorDigit("下", 4)
        }
    },
    gender: {
        kind: "radio",
        id: "gender",
        options: {
            "男": "男",
            "女": "女"
        }
    },
    gender_optional: {
        kind: "radio",
        id: "gender_optional",
        options: {
            "男": "男",
            "女": "女",
            "回答しない": "回答しない"
        }
    },
    word: {
        kind: "dom",
        domTemplate: "input_word",
    },
    address: {
        kind: "dom",
        domTemplate: "input_address",
        validate: {
            address: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    address_kana: {
        kind: "dom",
        domTemplate: "input_address_kana",
        validate: (element: FieldDomElement) => {
            const errors: string[] = [];
            if (!TemplateUtil.isKatakanaString(JSON.parse(element.value))) {
                errors.push("カタカナで入力してください");
            }
            return errors;
        }
    },
    building_info: {
        kind: "dom",
        domTemplate: "input_building_info",
        validate: {
            building_info: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    phone_number: {
        kind: "dom",
        domTemplate: "input_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("(XXX)___-____").value;
            const value2 = element.subInput("(___)XXX-____").value;
            const value3 = element.subInput("(___)___-XXXX").value;
            if (value1 || value2 || value3) {
                return JSON.stringify(`(${value1})-${value2}-${value3}`);
            }
            return "";
        },
        validate: {
            "(XXX)___-____": TemplateUtil.createValidatorDigit(),
            "(___)XXX-____": TemplateUtil.createValidatorDigit(),
            "(___)___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    local_phone_number: {
        kind: "dom",
        domTemplate: "input_local_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("XXX-____").value;
            const value2 = element.subInput("___-XXXX").value;
            if (value1 || value2) {
                return JSON.stringify(`${value1}-${value2}`);
            }
            return "";
        },
        validate: {
            "XXX-____": TemplateUtil.createValidatorDigit(),
            "___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    birthdate: {
        kind: "dom",
        domTemplate: "input_birthdate",
        value: (element: FieldDomElement) => {
            return JSON.stringify({
                year: TemplateUtil.convertToASCIIDigit(element.subInput("year").value),
                month: TemplateUtil.convertToASCIIDigit(element.subInput("month").value),
                date: TemplateUtil.convertToASCIIDigit(element.subInput("date").value)
            });
        },
        validate: {
            year: function (value) {
                const errors = [];
                const year = JSON.parse(this.value).year;
                if (!isFinite(year) || year < 2000) errors.push("正しい年を入力してください");
                return errors;
            },
            month: function (value) {
                const errors = [];
                const month = JSON.parse(this.value).month;
                if (!isFinite(month) || month <= 0 || month > 12) errors.push("正しい月を入力してください");
                return errors;
            },
            date: function (value) {
                const errors = [];
                const date = JSON.parse(this.value).date;
                if (!isFinite(date) || date <= 0 || date > 31) errors.push("正しい日を入力してください");
                return errors;
            }
        }
    },
    relation: {
        kind: "dom",
        domTemplate: "input_relation",
        validate: {
            relation: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    map: {
        kind: "dom",
        domTemplate: "input_map",
        manualUpdate: (element: FieldDomElement) => {
            element.subInput("select_button").addEventListener("click", () => {
                element.update();
            });
        },
        setup: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            if (!window.google) {
                console.error("failed to load google map module");
                return;
            }
            const map = new google.maps.Map(element.subInput("map"), {
                center: center,
                zoom: 15,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
            });
            map.addListener("click", (e) => {
                element.marker.setPosition(e.latLng);
            });
            map.setOptions({
                styles: [
                    { featureType: 'poi', stylers: [{ visibility: 'on' }] },
                    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                    { featureType: 'administrative', stylers: [{ visibility: 'on' }] },
                    { featureType: 'road', stylers: [{ visibility: 'simplified' }] }
                ]
            });
            element.mapObject = map;
            element.marker = new google.maps.Marker({
                position: center,
                map: map
            });
        },
        value: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            let data = {
                center,
                zoom: 15,
                marker: center
            };
            if (window.google) {
                data = {
                    center: element.mapObject.getCenter(),
                    zoom: element.mapObject.getZoom(),
                    marker: element.marker.position
                };
            }
            return JSON.stringify(data);
        }
    }
};

TemplateManager.addTemplates(templates);

function option(label: string, value: string): HTMLOptionElement {
    const option = document.createElement("option");
    option.value = value;
    option.textContent = label;
    return option;
}

function updateYears(element: FieldDomElement, eras) {
    const gengo = <HTMLSelectElement>element.subInput("gengo");
    const year = <HTMLSelectElement>element.subInput("year");
    const eraIndex = gengo.selectedIndex;
    const max = eras[eraIndex].max;
    let value = parseInt(year.value);
    year.innerHTML = "";
    for (let j = 0; j < max; j++) {
        year.appendChild(option(
            j == 0 ? "元" : String(j + 1), String(j + 1)
        ));
    }
    year.value = String(value > max ? 1 : value);
}

TemplateManager.addTemplate("date_in_japanese_calendar", {
    kind: "dom",
    domTemplate: "input_date_in_japanese_calendar",
    value: (element: FieldDomElement) => {
        return JSON.stringify({
            gengo: element.subInput("gengo").nodeValue,
            year: element.subInput("year").nodeValue,
            month: element.subInput("month").nodeValue,
            date: element.subInput("date").nodeValue
        });
    },
    setup: (element: FieldDomElement, definition) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const date = <HTMLSelectElement>element.subInput("date");
        const eras = definition.context.eras;
        for (let i = 0, l = eras.length; i < l; i++) {
            gengo.appendChild(option(eras[i].name, eras[i].value));
        }
        updateYears(element, eras);
        gengo.addEventListener("change", () => {
            updateYears(element, eras);
        });
        for (let i = 0; i < 12; i++) {
            month.appendChild(option(String(i + 1), String(i + 1)));
        }
        for (let i = 0; i < 31; i++) {
            date.appendChild(option(String(i + 1), String(i + 1)));
        }
        gengo.value = definition.context.initialValue.gengo;
        year.value = definition.context.initialValue.year;
        month.value = definition.context.initialValue.month;
        date.value = definition.context.initialValue.date;
    },
    validate: {
        gengo: function (value) {
            return [];
        },
        year: function (value) {
            return [];
        },
        month: function (value) {
            return [];
        },
        date: function (value) {
            return [];
        }
    }
});
