import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";

export class FormTextElement extends FormElement implements FieldInputElement {
  public id: string;
  disabled: boolean;
  validationErrors: string[];
  public readonly fieldsElement: HTMLElement;
  public readonly prefixElement: HTMLLabelElement;
  public readonly suffixElement: HTMLLabelElement;
  public readonly textElement: HTMLInputElement;

  constructor(namespace: string, name: string, template: DOMTemplate) {
    super(template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const fieldset = node("fieldset");
    this.prefixElement = node("label", {}, "");
    this.suffixElement = node("label", {}, "");
    let id = `${namespace}-${name}`;
    this.id = id;
    this.textElement = node("input", {
      type: 'text',
      name,
      'data-name': name
    });
    this.virtualInput.setAttribute("data-id", id);
    ["keyup", "paste", "cut"].forEach(event => {
      this.textElement.addEventListener(event, e => {
        this.updateValue();
      });
    });
    fieldset.appendChild(this.prefixElement);
    fieldset.appendChild(this.textElement);
    fieldset.appendChild(this.suffixElement);
    this.fieldsElement.appendChild(fieldset);
  }

  public focus() {
    this.textElement.focus();
  }

  private updateValue() {
    setTimeout(() => {
      this.value = this.textElement.value;
    }, 0);
  }
}