export class FormElement {
  public readonly element: HTMLElement;
  public readonly titleElement: HTMLElement;
  public readonly descriptionElement: HTMLElement;
  public readonly virtualInput: HTMLInputElement;
  public readonly errors?: HTMLElement;

  constructor(element: HTMLElement) {
    this.titleElement = <HTMLElement>element.querySelector("[data-role=title]");
    this.descriptionElement = <HTMLElement>element.querySelector("[data-role=description]");
    this.virtualInput = <HTMLInputElement>element.querySelector("[data-role=virtualInput]");
    const errors = <HTMLElement>element.querySelector("[data-role=errors]");
    if (errors) {
      errors.style.display = "none";
      this.errors = errors;
    }
    this.element = element;
  }

  public get title() {
    return this.titleElement.textContent;
  }

  public set title(title: string) {
    this.titleElement.textContent = title;
  }

  public get description() {
    return this.descriptionElement.textContent;
  }

  public set description(description: string) {
    if (description) {
      this.descriptionElement.textContent = description;
      this.descriptionElement.style.display = "";
    } else {
      this.descriptionElement.textContent = "";
      this.descriptionElement.style.display = "none";
    }
  }

  public get required() {
    return this.titleElement.classList.contains("required");
  }

  public set required(required: boolean) {
    if (required) {
      this.titleElement.classList.add("required");
    } else {
      this.titleElement.classList.remove("required");
    }
  }

  get value() {
    return JSON.parse(this.virtualInput.value);
  }

  set value(value: any) {
    this.virtualInput.value = JSON.stringify(value);
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    this.virtualInput.dispatchEvent(e);
  }

  get hidden() {
    return this.element.style.display === "none";
  }

  set hidden(value) {
    if (value) {
      this.element.style.display = "none";
    } else {
      this.element.style.display = "";
      this.element.removeAttribute("style");
    }
  }
}