import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";

export class FormTextAreaElement extends FormElement implements FieldInputElement {
  public id: string;
  disabled: boolean;
  validationErrors: string[];
  public readonly fieldsElement: HTMLElement;
  public readonly textAreaElement: HTMLInputElement;

  constructor(namespace: string, name: string, template: DOMTemplate) {
    super(template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const fieldset = node("fieldset");
    let id = `${namespace}-${name}`;
    this.id = id;
    this.textAreaElement = node("textarea", {
      name,
      'data-name': name
    });
    ["keyup", "paste", "cut"].forEach(event => {
      this.textAreaElement.addEventListener(event, e => {
        this.updateValue();
      });
    });
    this.virtualInput.setAttribute("data-id", id);
    fieldset.appendChild(this.textAreaElement);
    this.fieldsElement.appendChild(fieldset);
  }

  public focus() {
    this.textAreaElement.focus();
  }

  private updateValue() {
    setTimeout(() => {
      this.value = this.textAreaElement.value;
    }, 0);
  }
}