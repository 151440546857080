import { BindDataSource } from "../bind";
import { DigitalForm } from "./DigitalForm";

export class DigitalFormDataSource implements BindDataSource {
  private readonly digitalForm: DigitalForm;
  private readonly id: string;
  private readonly src: HTMLInputElement | HTMLSelectElement;

  constructor(digitalForm: DigitalForm, id: string) {
    this.digitalForm = digitalForm;
    this.id = id;
    const src = digitalForm.element.querySelector(`[data-id="${id}"]`);
    if (!src) {
      throw new Error(`data not found: ${id}`);
    }
    this.src = src;
  }

  get value(): string {
    return this.src.value;
  }

  focus(dst: HTMLElement): void {
    const token = this.id.split("-");
    const boxId = token[0];
    const box = this.digitalForm.box(boxId);
    box.open();
    this.src.focus();
  }

  addChangeListener(callback: () => void): void {
    this.src.addEventListener('change', callback);
  }
}